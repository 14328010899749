import React from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

// layout 
import AppLayout from '../../../layouts/AppLayout/AppLayout';

// components
import { NioButton, NioSection, NioCount, NioCard, NioMedia, NioSubscribeField } from '../../../components';

// section content 
import TestimonialContent from '../../../components/PageComponents/Homepages/LanguageLearning/TestimonialContent/TestimonialContent';

function index() {
  return (
    <AppLayout >

      {/*  Banner Section Start   */}
      <section className="nk-banner ">
        {/* <div className="nk-frame-children nk-frame-children-one">
          <img src="images/language-learning/icon-globe.png" alt="icon" className="animate animate-infinite animate-pulse animate-duration-2" />
        </div>
        <div className="nk-frame-children nk-frame-children-two">
          <img src="images/language-learning/icon-love.png" alt="icon" className="animate animate-infinite animate-pulse animate-duration-3" />
        </div>
        <div className="nk-frame-children nk-frame-children-three">
          <img src="images/language-learning/icon-en.png" alt="icon" className="animate animate-infinite animate-pulse animate-duration-3" />
        </div>
        <div className="nk-frame-children nk-frame-children-four">
          <img src="images/language-learning/icon-cn.png" alt="icon" className="animate animate-infinite animate-pulse animate-duration-2" />
        </div> */}
        <div className="nk-banner-wrap">
          <Container>
            <Row className="justify-content-center pt-lg-7">
              <Col xl={10}>

                <div className="text-center mb-2 mb-lg-0">
                  <span className="d-inline-block text-bg-primary text-white fs-14 rounded-pill text-uppercase fw-semibold py-1 px-3 mb-4" >Log Retrieval</span>
                  <h1 className="display-6 pb-1 mb-0">
                    <span className="bg-white" >Accessing and Analyzing System Logs</span>
                  </h1>
                  <p className="fs-20" >Log retrieval is an essential practice for monitoring and troubleshooting computer systems, networks, and applications. It involves accessing and analyzing logs generated by various components of a system. This page provides a comprehensive overview of how log retrieval works, the steps to follow, and key considerations for efficient log analysis. </p>
                  {/* <div className="pt-5">
                    <ul className="d-flex justify-content-center flex-wrap gap-3">
                      <li className="media media-md media-middle media-circle d-inline-block bg-white p-1"  >
                        <img
                          alt="flag"
                          src="images/icon/flag-1.png"
                          className="animate animate-pulse animate-infinite animate-duration-1s animate-delay-1"
                        />
                      </li>
                      <li className="media media-md media-middle media-circle d-inline-block bg-white p-1" >
                        <img
                          alt="flag"
                          src="images/icon/flag-2.png"
                          className="animate animate-pulse animate-infinite animate-duration-1s" />
                      </li>
                      <li className="media media-md media-middle media-circle d-inline-block bg-white p-1" >
                        <img
                          alt="flag"
                          src="images/icon/flag-3.png"
                          className="animate animate-pulse animate-infinite animate-duration-1s animate-delay-1" />
                      </li>
                      <li className="media media-md media-middle media-circle d-inline-block bg-white p-1" >
                        <img
                          alt="flag"
                          src="images/icon/flag-4.png"
                          className="animate animate-pulse animate-infinite animate-duration-1s"
                        />
                      </li>
                      <li className="media media-md media-middle media-circle d-inline-block bg-white p-1" >
                        <img
                          alt="flag"
                          src="images/icon/flag-5.png"
                          className="animate animate-pulse animate-infinite animate-duration-1s animate-delay-1" />
                      </li>
                    </ul>
                    <div className="pt-5" >
                      <NioButton href="#" label="Start Learning" className="btn-dark" />
                    </div>
                  </div> */}
                </div>
              </Col>
            </Row>
            {/* <Row className="align-items-center justify-content-center pt-5 pt-lg-7">
              <Col xl={10}>
                <h5 className="text-white text-center fw-normal mb-5" >Over 32K+ Software Business Growing With NioLand.</h5>
                <Row className="gy-2 gap-sm-2 justify-content-center text-center align-items-center">
                  {
                    ["a", "b", "c", "d", "e"].map((brand, idx) => (
                      <Col key={idx} xs={4} sm={3} lg={2} >
                        <Link className="nk-brand" to="#">
                          <img src={`images/brands/${brand}-light.png`} alt="brand" />
                        </Link>
                      </Col>
                    ))
                  }
                </Row>
              </Col>
            </Row> */}
          </Container>
        </div>
      </section>
      {/*  Banner Section End   */}

      {/* learn language Section Start */}
      {/* <NioSection className="nk-section-language position-relative pt-7 pt-lg-120">
        <NioSection.Content>
          <Row className="align-items-center justify-content-between">
            <Col lg={6}>
              <div className="text-center mb-5 mb-lg-0" >
                <img src="images/language-learning/section-cover-1.png" alt="section-cover" />
              </div>
            </Col>
            <Col lg={6} xl={5}>
              <div className="nk-section-head pb-0">
                <h2 className="mb-3">The <span className="text-info-alt">world's #1</span> way to learn a language </h2>
                <p className="fs-20"> Learning with us is fun, and research shows that it works! With quick, bite-sized lessons, you’ll earn points and unlock new levels while gaining real-world communication skills. Learn a language right here with hundreds of free language-learning lessons. Get access to compact lessons from the experts. </p>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection> */}

      {/* learn language Section End */}

      {/* Changeing Section Start */}
      <NioSection>
        <NioSection.Head alignX="center">
          <h2>
            <span className="text-indigo-alt"></span> How Log Retrieval Works
          </h2>
          <p>Log retrieval is the process of obtaining log files or entries from different parts of a system. These logs may contain information about events, errors, user actions, and performance metrics. Here's a simplified overview of how it works</p>
        </NioSection.Head>

        <NioSection.Content>
          <Row className="gy-5">
            <Col md={6} lg={4} >
              <NioCard>
                <NioCard.Body>
                  <NioMedia size="lg" rounded variant="info text-white" icon="globe" className="mb-4" />
                  <h5> Log Generation </h5>
                  <p> Logs are generated automatically by software, hardware, or systems. These logs capture important events and activities.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4} >
              <NioCard>
                <NioCard.Body>
                  <NioMedia size="lg" rounded variant="warning text-white" icon="book-read" className="mb-4" />
                  <h5>Log Storage </h5>
                  <p>Logs are typically stored in files or databases, organized by time and source. Some systems use centralized log servers.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4} >
              <NioCard>
                <NioCard.Body>
                  <NioMedia size="lg" rounded variant="indigo text-white" icon="text2" className="mb-4" />
                  <h5> Request for Logs</h5>
                  <p>To retrieve logs, you send requests to the relevant sources, such as servers, network devices, or applications.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4} >
              <NioCard>
                <NioCard.Body>
                  <NioMedia size="lg" rounded variant="danger text-white" icon="chat-circle" className="mb-4" />
                  <h5> Access and Retrieval </h5>
                  <p>The requested logs are accessed and retrieved, either manually or through automated log collection tools.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4} >
              <NioCard>
                <NioCard.Body>
                  <NioMedia size="lg" rounded variant="info text-white" icon="todo" className="mb-4" />
                  <h5>Log Analysis</h5>
                  <p> The retrieved logs are analyzed to gain insights into system behavior, detect issues, or track user actions.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4} >
              <NioCard>
                <NioCard.Body>
                  <NioMedia size="lg" rounded variant="success text-white" icon="clock" className="mb-4" />
                  <h5> Reporting and Alerts </h5>
                  <p>Analysis results can be used to generate reports, trigger alerts, or perform further actions, such as system maintenance.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/* Changeing Section End */}

      {/* Dialect Section Start */}
      <NioSection>
        <NioSection.Content>
          <Row className="flex-lg-row-reverse align-items-center justify-content-between  pb-5 pb-lg-7">
            <Col lg={6}>
              <div className="text-center mb-5 mb-lg-0" >
                <img src="images/language-learning/retrival.png" alt="section-cover" />
              </div>
            </Col>
            <Col lg={5}>
              <div>
                <span className="d-inline-block text-bg-primary text-white fs-14 rounded-pill text-uppercase fw-semibold py-1 px-3 mb-4" >Step to follow</span>

                <h2> Log retrieval involves several steps</h2>
                <p className="fs-20"> Log retrieval is a multifaceted process that encompasses several key steps, each playing a crucial role in obtaining and analyzing system or application logs. The first step involves identifying the specific logs required for analysis, which may include application logs, server logs, or error logs, depending on the context.</p>
                <div className="mt-5">
                  <NioButton href="https://ibprocess.intellibooks.io/signin" target="_blank" label="Join With Us" className="btn-info-alt" />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="pt-5 pt-lg-7">
            <Col sm={6} lg={3} >
              <div className="text-center mb-5 mb-lg-0">
                {/* <div className="h1 mb-2">
                  <NioCount end={70} suffix="k" />
                  <span className="text-info-alt"> +</span>
                </div> */}
                <h5>Identify Log Sources</h5>
                <p> Determine which components or systems generate the logs you need to retrieve. Common sources include servers, network devices, databases, and applications. </p>
              </div>
            </Col>
            <Col sm={6} lg={3} >
              <div className="text-center mb-5 mb-lg-0">
                {/* <div className="h1 mb-2">
                  <NioCount end={40} suffix="k" />
                  <span className="text-info-alt"> +</span>
                </div> */}
                <h5>Access Permissions</h5>
                <p> Ensure you have the necessary permissions and access credentials to retrieve logs from the identified sources. </p>
              </div>
            </Col>
            <Col sm={6} lg={3} >
              <div className="text-center mb-5 mb-lg-0">
                {/* <div className="h1 mb-2">
                  <NioCount end={11} />
                  <span className="text-info-alt"> +</span>
                </div> */}
                <h5> Choose Retrieval Methods</h5>
                <p>Select the appropriate methods for retrieving logs, which may include manual retrieval, log forwarding, or log collection tools. </p>
              </div>
            </Col>
            <Col sm={6} lg={3} >
              <div className="text-center mb-5 mb-lg-0">
                {/* <div className="h1 mb-2">
                  <NioCount end={15} suffix="k" />
                  <span className="text-info-alt"> +</span>
                </div> */}
                <h5> Define Retrieval Schedule</h5>
                <p> Decide whether log retrieval should occur in real-time, at regular intervals, or based on specific triggers or events.</p>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>

      {/* Dialect Section End */}

      {/* testimonial Section Start */}
      <NioSection className="nk-section-testimonials pb-7 pb-lg-120">
        <NioSection.Head alignX="center">
          <h2>
            Key Points to Consider

          </h2>
          <p className="fs-20 mb-0">Here are some important considerations for effective log retrieval.</p>
        </NioSection.Head>
        <NioSection.Content>
          <TestimonialContent />
        </NioSection.Content>
      </NioSection>
      {/* testimonial Section End */}


      <NioSection className="nk-section-testimonial">
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="nk-section-head text-center pb-5">
              <span className="fs-14 fw-semibold text-uppercase d-inline-block text-primary mb-2">Log retrieve</span>
              <h2 className="mb-0">Conclusion</h2>
              <p>Log retrieval is a crucial practice for system monitoring, troubleshooting, and security analysis. By following the steps outlined here and considering the key points, you can efficiently access and analyze logs to gain valuable insights into your systems and applications.
                Explore the world of log retrieval and unlock the power of log data for maintaining system health and security.</p>
              <ul className="nk-btn-group justify-content-center pt-5">
                <li>
                  <NioButton href="https://ibprocess.intellibooks.io/signin" target="_blank" className="btn-purple" label="Contact Us" />
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        {/* <NioSection.Content className="overflow-hidden">
          <div className="mx-lg-n9">
            <div className="mx-xl-n9">
              <TestimonialContent />
            </div>
          </div>
        </NioSection.Content> */}
      </NioSection>



      {/* CTA Section Start */}
      {/* <NioSection className="nk-section-cta bg-info-alt bg-2">
        <NioSection.Content>
          <Row className="align-items-center justify-content-between position-relative">
            <Col xl={5}>
              <div className="text-center text-xl-start pb-5 pb-lg-7 pb-xl-0">
                <h2>
                  <span className="bg-white d-inline-block p-3 rounded-2 mb-2 mb-lg-4 me-1" >Thinking about</span>
                  <span className="bg-indigo-alt text-white d-inline-block p-3 rounded-2 me-1 text-nowrap" >language learning?</span>
                </h2>
              </div>
            </Col>
            <Col xl={5}>
              <div className="text-center text-xl-start">
                <p className="fs-20 text-dark"> Learn a language right here with hundreds of free language-learning lessons. Get access to compact lessons from the experts and connect with a community of native speakers to help you master words faster. </p>
                <ul className="d-flex flex-wrap justify-content-center justify-content-xl-start gap-3 pt-5">
                  {
                    [1, 2, 3, 4, 5].map((flag, idx) => (
                      <li key={idx} className="media media-md media-middle media-circle d-inline-block bg-white p-1">
                        <img src={`images/icon/flag-${flag}.png`} alt="flag" />
                      </li>
                    ))
                  }
                </ul>
                <div className="pt-5">
                  <NioButton href="#" label="Start Learning" className="btn-dark" />
                </div>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection> */}
      {/* CTA Section End */}

      {/* NewsLetter Section Start */}
      {/* <NioSection className="nk-newsletter-section pt-7 pb-lg-0">
        <Row className="justify-content-center justify-content-lg-between align-items-center pb-5 border-bottom border-lighter">
          <Col lg={6} xl={4}>
            <div className="nk-newsletter-content text-center text-lg-start pb-5 pb-lg-0">
              <h4 className="text-capitalize">Subscribe to our newsletter</h4>
              <p className="fs-16">Join the 5000+ People That Uses Softnio Products.</p>
            </div>
          </Col>
          <Col md={10} lg={6} xl={5}>
            <NioSubscribeField variant="one" />
          </Col>
        </Row>
      </NioSection> */}
      {/* NewsLetter Section End */}

    </AppLayout>
  )
}

export default index;